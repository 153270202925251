export class RMValidationError extends Error {
  constructor(message) {
    if (typeof message === 'string')
      super(`[RemoteModules validation error] \n ${message}`)
    else super(message)
  }
}
export function awaitAsynWrapper(getFun) {
  return async (...args) => {
    const data = await getFun(...args)
    if (data == null)
      throw new RMValidationError(
        `fetch return ${JSON.stringify(data)}, Not valid for Remote Modules`
      )
    return data
  }
}
