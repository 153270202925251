
const USER_CENTRIC = 'USER_CENTRIC'
const UTOKEN = 'UTOKEN'
/**
 * @typedef SettingState
 * @property {boolean} userCentricLoaded
 */
export default {
  /** @type {SettingState} */
  state:{
    userCentricLoaded:false,
    utoken: null
  },
  getters:{
    /**
     * 
     * @param {SettingState} state 
     * @returns 
     */
    isUserCentricLoaded: state => state.userCentricLoaded,
    getUtoken: state => state.utoken,
  },
  mutations: {
    /**
     * 
     * @param {SettingState} state 
     * @param {*} boolean 
     */
    [USER_CENTRIC](state,boolean){
      state.userCentricLoaded = boolean
    },
    [UTOKEN](state, utoken) {
      state.utoken = utoken
    }  
  },
  actions: {
    loadUserCentric({commit}){
      commit(USER_CENTRIC,true)
    },
    setUtoken({commit}, utoken) {
      commit(UTOKEN, utoken)
    }
  },
}
