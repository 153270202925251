import en from './lang/en.js'
import de from './lang/de.js'

import store from '@/store'

const DEFAULT_APP_LANG = 'de'

let storeInstance = store
let allStrings = {
  en: { ...en },
  de: { ...de },
}
export const langs = Object.keys(allStrings)
export function initLang() {
  let lang = navigator.language.split('-')[0] || DEFAULT_APP_LANG
  if (Object.keys(allStrings).indexOf(lang) < 0) lang = Object.keys(allStrings)[0]
  storeInstance.dispatch('setLanguage', lang)
  return storeInstance.state.i18n.lang
}
function replaceArticles(str, config = { gender: 'M', who: 'self' }) {
  let keys, propperKey, propperKeyNoG, g, w
  if (!str || str.indexOf('$') < 0 || str.indexOf('[') === 0) return str
  else keys = str.match(/\$[a-zA-Z_]*/g)
  // g = store.getState().session.gender
  // if(g==='U') g = 'M'
  g = config.gender //Remember this is diferents that GENDER_ENUM.MALE. This is in order to find the key in lang
  // w = store.getState().session['for-who']
  w = config.who
  return keys.reduce((result, key) => {
    propperKey = w === 'self' ? key + '_self' : key + '_other_' + g
    propperKeyNoG = w === 'self' ? key + '_self' : key + '_other'
    return result.replace(key, flatT(propperKey) || flatT(propperKeyNoG) || '[' + propperKey + ']')
  }, str)
}
function flatT(key) {
  return allStrings[storeInstance.state.i18n.lang][key]
}

export function t(key, config = { gender: 'M', who: 'self' }) {
  let lang = storeInstance.state.i18n.lang || initLang()
  // hace "fallback" a idiomas "parceidos" (es <-> es-ES)
  let availableLang =
    Object.keys(allStrings).indexOf(lang) >= 0
      ? lang
      : Object.keys(allStrings).find(l => l.indexOf(lang) >= 0 || lang.indexOf(l) >= 0)

  let aux = replaceArticles(allStrings[availableLang][key], config)
  return aux != undefined ? aux : '[' + key + ']'
}
export function getLocalLanguage() {
  if (navigator && navigator.language) {
    let localLang = navigator.language
    console.log('Local Lang: ' + localLang)
    if (langs.some(e => e === localLang)) return localLang
  }
  return 'en'
}
export default {
  methods: {
    $t: t,
  },
}
