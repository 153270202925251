<template>
  <div id="app" :class="['main', deviceClass, routeClassName]">
    <main-header />
    <transition name="slide-fade">
      <progress-header v-if="showProgressHeader" />
    </transition>
    <div class="view-wrapper">
      <div v-if="isMobile" class="bg-mobile-top" />
      <div v-if="isMobile" class="bg-mobile-bottom" />
      <router-view />
    </div>
    <next-back-mobile-footer v-if="showFooterMobile" />
    <selected-perfumes-modal />
    <footer-component v-if="!isMobile" />
  </div>
</template>


<script>
import device from '@/utils/device'
import { getCustomerId } from '@/services/Api'
import ResponsiveCss from './mixins/ResponsiveCss'
import MainHeader from './components/MainHeader'
import ProgressHeader from './components/ProgressHeader'
import { ROUTER_NAMES } from './settings'
import { mapActions } from 'vuex'
import NextBackMobileFooter from './components/NextBackMobileFooter.vue'
import SelectedPerfumesModal from './components/SelectedPerfumesModal.vue'
import FooterComponent from './components/Footer.vue'

export default {
  components: {
    MainHeader,
    ProgressHeader,
    NextBackMobileFooter,
    SelectedPerfumesModal,
    FooterComponent,
  },
  mixins: [ResponsiveCss],
  computed: {
    deviceClass() {
      return device
    },
    utoken() {
      return this.$store.state.settings.utoken
    },
    routeClassName() {
      if (this.$route.name)
        return this.$route.name
          .toLowerCase()
          .split(' ')
          .join('-')
      return ''
    },
    showFooterMobile() {
      return (
        this.isMobile &&
        this.$route.name !== ROUTER_NAMES.WELCOME &&
        this.$route.name !== ROUTER_NAMES.PERFUME &&
        this.$route.name !== ROUTER_NAMES.RECOMMENDATIONS
      )
    },
    showProgressHeader() {
      return this.$route.name !== ROUTER_NAMES.WELCOME && this.$route.name !== ROUTER_NAMES.PERFUME
    },
  },
  watch: {
    $route(newRoute, oldRoute) {
      this.handleLogin()
      if(newRoute.name !== oldRoute.name && newRoute.name === ROUTER_NAMES.SEARCH_FRAGRANCE) {
        this.eventTracker.emitEvent('VIEW_OPENED', { type: 'EXPLORE_BY_PERFUMES' })
      }
    }
  },
  mounted() {
    if(!this.utoken) {
      this.handleLogin()
    } else {
      this.removeUtokenFromUrl()
    }
  },
  methods: {
    ...mapActions({ fetchUserIfLogged: 'profiler/init' }),
    removeUtokenFromUrl() {
      let query = Object.assign({}, this.$route.query);
      delete query.utoken;
      this.$router.replace({ query });
    },
    handleLogin() {
      const params = new URLSearchParams(window.location.search)
      if (params.has('utoken')) {
        const utoken = params.get('utoken')
        if (this.utoken !== utoken) {
          getCustomerId(utoken).then(({ customerId }) => {
            this.eventTracker.uuid = customerId
            this.$store.dispatch('setUtoken', utoken)
          })
        }
        this.removeUtokenFromUrl()
      } 
    }
  },
  
}
</script>

<style lang="stylus">
@import "./styles/main"

body
  background-color: #ddd

  .slide-fade-enter-active
    transition: all 0.3s

  .slide-fade-leave-active
    position: absolute
    transition: all 0.3s

  .slide-fade-enter,
  .slide-fade-leave-to
    opacity: 0
    transform: translateY(-10px)

  .view-wrapper
    position: relative
    // overflow-x: hidden
    // overflow-y: auto
    overflow: hidden
    height: "calc(100% - %s)" % $header-height
    background-color: #fff

#app
  position: fixed
  display: flex
  flex-direction: column
  overflow: hidden
  width: 100%
  height: 100%

.main
  &.mobile
    &.welcome,
    &.search-fragrance,
    &.concepts,
    &.gender,
    &.password
      .view-wrapper
        .bg-mobile-top,
        .bg-mobile-bottom
          position: absolute
          left: 0
          width: 100%
          height: 50%
          background-position: center center
          background-size: cover
          background-repeat: no-repeat

          &:before
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            background-color: #fff
            content: ""
            opacity: 0.75

        .bg-mobile-top
          top: 0
          background-image: url("~@/assets/bg-mobile-top.jpg")

        .bg-mobile-bottom
          bottom: 0
          background-image: url("~@/assets/bg-mobile-bottom.jpg")
  &.mobile
    &.welcome
      .view-wrapper
        .bg-mobile-top,
        .bg-mobile-bottom
          &:before
            opacity: 0

  &.welcome,
  &.search-fragrance,
  &.concepts,
  &.gender
    .view-wrapper
      background-image: url("~@/assets/bg-01.jpg")
      background-position: center center
      background-size: cover
      background-repeat: no-repeat

  &.search-fragrance,
  &.concepts,
  &.gender
    .view-wrapper
      &:before
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background-color: #fff
        content: ""
        opacity: 0.75
</style>
