<template>
  <div class="selected-fragrances">
    <basic-loading v-if="isPerfumesUpdating" />
    <div v-else class="selected-fragrances-inner">
      <div v-if="!isMobile" class="selected-fragrances-header">
        <div class="selected-fragrances-quantity">{{ $t('search_fragrance_quantity') }} ({{ quantity }})</div>
        <div class="selected-fragrances-remove" @click="handleRemoveAll">{{ $t('search_fragrance_remove') }}</div>
      </div>
      <transition-group tag="div" name="list-complete" class="selected-fragrances-list">
        <fragrance
          v-for="perfume in perfumesFiltered"
          :key="perfume.id"
          class="list-complete-item"
          :perfume="perfume"
          :removable="canRemoved"
          @remove="()=>handleRemove(perfume.id)"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import QueryStringMixin from '../../mixins/QueryStringMixin'
import { ROUTER_NAMES } from '../../settings'
import BasicLoading from '../basic/BasicLoading.vue'
import Fragrance from './Fragrance.vue'
export default {
  components: { BasicLoading, Fragrance },
  remoteComputed: {
    perfumes: {
      model: 'perfumes/search',
      method: 'getById',
      params() {
        return [this.qsSelectedPerfumes]
      },
    },
  },
  mixins: [QueryStringMixin],
  computed: {
    perfumesFiltered() {
      return this.perfumes.filter(p => p)
    },
    quantity() {
      return this.qsSelectedPerfumes.length
    },
    canRemoved() {
      if (this.$route.name !== ROUTER_NAMES.SEARCH_FRAGRANCE && this.qsSelectedPerfumes.length <= 2)
        return false
      return true
    },
  },
  watch: {
    qsSelectedPerfumes(newPerfumes, oldPerfumes) {
      const resultingProfile = { perfumes: newPerfumes.map(p => parseInt(p)), concepts: this.qsConcepts.map(c => parseInt(c)), forWhom: this.qsForMe ? 'ME' : 'THEM' }
      const added = newPerfumes.filter(p => !oldPerfumes.includes(p))
      const removed = oldPerfumes.filter(p => !newPerfumes.includes(p))
      added.map(a => this.eventTracker.emitEvent('ADD_TO_PROFILE', {
        id: parseInt(a),
        type: 'PERFUME',
        resultingProfile
      }))
      removed.map(r => this.eventTracker.emitEvent('REMOVE_FROM_PROFILE', {
        id: parseInt(r),
        type: 'PERFUME',
        resultingProfile
      }))
    }
  },
  methods: {
    handleRemoveAll() {
      this.$pushHistory({
        name: ROUTER_NAMES.SEARCH_FRAGRANCE,
        query: { ...this.$route.query, qsSelectedPerfumes: [] },
      })
    },
    handleRemove(id) {
      const index = this.qsSelectedPerfumes.indexOf(id.toString())
      if (index < 0) {
        console.error('index of id =', id, ' not found')
        return
      }
      const copy = [...this.qsSelectedPerfumes]
      copy.splice(index, 1)
      this.qsSelectedPerfumes = copy
    },
  },
}
</script>

<style lang="stylus" scoped>
.selected-fragrances
  overflow: hidden
  height: 100%

  .list-complete-item
    background-color: #fff
    opacity: 1
    transition: all 0.8s ease-in-out
    transform: translateX(0)

  .list-complete-enter,
  .list-complete-leave-to
    opacity: 0
    transform: translateX(100%)

.selected-fragrances-inner
  display: flex
  flex-direction: column
  overflow: hidden
  height: 100%

.selected-fragrances-header
  display: flex
  align-items: center
  padding: vh(25px) vw(20px)
  background-color: #fff

.selected-fragrances-quantity
  m-font-size(14, 22)
  flex: 1 1 0%
  font-weight: 600

.selected-fragrances-remove
  m-font-size(14, 22)
  color: #000
  text-align: center
  text-decoration: underline
  cursor: pointer

.selected-fragrances-list
  flex: 1 1 0%
  overflow-x: hidden
  overflow-y: auto
</style>