<template>
  <div class="loading overlay">
    <div class="spinner"><div /><div /><div /><div /></div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="stylus" scoped>
.loading
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(#fff, 0.9)

  .spinner
    position: absolute
    top: 50%
    left: 50%
    width: vw(80px)
    height: vw(80px)
    transform: translate(-50%, -50%)

  .spinner
    > div
      position: absolute
      display: block
      box-sizing: border-box
      margin: 8px
      width: 64px
      height: 64px
      border: 8px solid $primary-theme-color
      border-color: $primary-theme-color transparent transparent transparent
      border-radius: 50%
      animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite

  .spinner
    > div
      &:nth-child(1)
        animation-delay: -0.45s

  .spinner
    > div
      &:nth-child(2)
        animation-delay: -0.3s

  .spinner
    > div
      &:nth-child(3)
        animation-delay: -0.15s

  @keyframes spinner
    0%
      transform: rotate(0)

    100%
      transform: rotate(360deg)
</style>