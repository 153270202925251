<template>
  <div :class="['number-badge', {'disabled': !number}]" @click="$emit('click',$event)">
    <div class="number" :class="{translate}">{{ number }}</div>
  </div>
</template>

<script>
export default {
  props: {
    number: Number,
    translate: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="stylus" scoped>
.number-badge
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  overflow: hidden
  width: vw(20px)
  height: vw(20px)
  border-radius: 50%
  background-color: #ea468f
  color: #fff
  text-align: center
  m-font-size(10, 0)
  will-change: transform

  &.disabled
    background-color: #E6E6E6 !important

  &.translate
    transition: transform 0.13s
    transform: scale(1) translate(13px, -6px)
</style>