const KEY = process.env.VUE_APP_ID ? process.env.VUE_APP_ID + '-lang' : 'lang'
import { eventTracker } from '@/services/track'
export default {
  state: {
    lang: localStorage.getItem(KEY) || null,
  },
  mutations: {
    setLanguage(state, lang) {
      eventTracker.emitEvent('LANGUAGE_CHANGED', { lang })
      state.lang = lang
    },
  },
  actions: {
    setLanguage(context, lang) {
      localStorage.setItem(KEY, lang)
      //context.dispatch('clearCache')
      context.commit('setLanguage', lang)
    },
  },
}
