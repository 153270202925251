import i18n from './modules/i18n'

import Vue from 'vue'
import perfumes from './modules/perfumes'
import concepts from './modules/concepts'
import families from './modules/families'
import brands from './modules/brands'
import profiler from './modules/profiler'
import settings from './modules/settings'

import RemoteVuex from './lib/remoteVuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['profiler'],
})
Vue.use(RemoteVuex)

export default new RemoteVuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    i18n,
    perfumes,
    concepts,
    brands,
    profiler,
    families,
    settings
  },
  plugins: [vuexLocal.plugin],
})
