import { api } from '../../services'

export default {
  remoteModels: {
    index: {
      get: () => api.getBrands(),
      lang: rootState => rootState.i18n.lang,
    },
  },
  mutations: {},
  actions: {},
}
