<template>
  <div class="progress-header">
    <div class="progress-header-container">
      <div class="icon donut" @click="goToHome" />
      <div v-if="!isMobile" class="stepper-container">
        <stepper :next-enable="!nextDisabled" />
      </div>
      <template v-if="!isMobile">
        <back-button />
        <next-button />
      </template>
      <template v-else>
        <div class="mobile-title">{{ currentStep ? currentStep.title : '' }}</div>
        <dot
          class="progress-dot"
          :number="qsSelectedPerfumes.length"
          :translate="false"
          @click="handleClickDot"
        />
      </template>
    </div>
    <stepper-bar v-if="isMobile" />
  </div>
</template>

<script>
import QueryStringMixin from '../../mixins/QueryStringMixin'
import { ROUTER_NAMES } from '../../settings'
import Stepper from './Stepper.vue'
import StepperBar from './StepperBar.vue'
import BackButton from '../buttons/BackButton.vue'
import NextButton from '../buttons/NextButton.vue'
import Dot from '../MainHeader/Dot.vue'
import StepHeaderMixin from '../../mixins/StepHeaderMixin'
export default {
  components: { Stepper, StepperBar, BackButton, NextButton, Dot },
  mixins: [QueryStringMixin, StepHeaderMixin],

  methods: {
    goToHome() {
      this.$routerPush({ name: ROUTER_NAMES.WELCOME })
    },
    handleClickDot() {
      this.qsIsSelectedOpen = true
    },
  },
}
</script>

<style lang="stylus" scoped>
.progress-header
  flex: 0 0 $sub-header-height
  overflow: hidden
  padding-right: vw(15px)
  padding-left: vw(50px)
  width: 100%
  height: $sub-header-height
  border-bottom: solid 1px #ccc
  background-color: #fff

  .desktop &
    position: absolute
    top: $header-height
    left: 0
    z-index: 1

  .mobile &
    display: flex
    flex: 0 0 $sub-header-height-mobile
    flex-direction: column
    padding: 0
    height: $sub-header-height-mobile
    border-bottom: none

  .progress-header-container
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    height: 100%

    .mobile &
      position: relative
      flex: 1 1 0%
      padding: vw(10px)

    .icon
      &.donut
        m-icon("donut", 36)

        &:hover
          cursor: pointer

    .stepper-container
      display: flex
      flex: 1 1 0%
      justify-content: center
      align-items: center
      height: 100%

    .progress-dot
      m-font-size(15, 15)
      position: relative
      width: vh(65px)
      height: vh(65px)
      background-color: $primary-theme-color-dark

      .mobile &
        width: vw(32px)
        height: vw(32px)

  .mobile-title
    m-font-size(17, 22)
    position: absolute
    top: 50%
    left: 50%
    width: 65%
    text-align: center
    font-weight: 700
    transform: translate(-50%, -50%)
</style>