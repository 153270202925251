
/** @type {import('vue-router').Route[]} @readonly*/
const _stacks = []
/**
 *
 * @param {import('vue-router').Route} to
 * @param {import('vue-router').Route} from
 * @param {()=>void} next
 */
export default function stackMiddleware(to,from,next){
  _stacks.push(to)
  next()
}
export function getStacks(){
  return _stacks
}