import { api } from '../../services'

export default {
  remoteModels: {
    index: {
      get: ({ rootState }) => api.getConcepts(rootState.i18n.lang),
      lang: rootState => rootState.i18n.lang,
      isList: true,
    },
  },
  mutations: {},
  actions: {},
}
