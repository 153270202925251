<template>
  <transition name="slide">
    <div v-if="showPanel" class="panel">
      <div class="close" @click="$emit('change',false)" />
      <div class="groups">
        <div class="group">
          <a 
            v-for="link in links" 
            :key="link.name" 
            class="item"
            :href="link.href" 
            target="_blank"
          >{{ link.name }}</a> 
          <a class="item" @click="handleOpenCookies();$emit('change',false)">{{ $t('cookies_polices') }}</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import QueryStringMixin from '../../mixins/QueryStringMixin'
export default {
  mixins:[QueryStringMixin],
  model:{
    prop:'showPanel',
    event:'change'
  },
  props:{
    showPanel:Boolean
  },
  data(){
    return {
      openModal:false
    }
  },
  computed:{
    links(){
      return [{
        name:this.$t('data_protection'),
        href:'https://www.douglas.de/de/cp/helpdataprotection/help-dataprotection'
      },
      {
        name: this.$t('help_imprint'),
        href:'https://www.douglas.de/de/cp/helpimprint/help-imprint'
      }]
    }
  },
  
  methods:{
    handleOpenCookies(){
    // eslint-disable-next-line
    if(UC_UI)
      // eslint-disable-next-line
      UC_UI.showFirstLayer()
    else
      console.error('[USER CENTRIC] user centric variable not found')
    }
  },
}
</script>

<style lang="stylus" scoped>
.panel
    position: absolute
    top: 0
    left: 0
    z-index: 3
    padding: vh(40px)
    width: 90vw
    height: 100vh
    background-color: #fff
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05)

    &.slide-enter-active,
    &.slide-leave-active
      transition: transform 0.5s

    &.slide-enter,
    &.slide-leave-to
      transform: translate(-100%, 0)

    .close
      m-icon("close", 20)
      position: absolute
      top: vw(20px)
      right: vw(20px)

    .groups
      margin-top: vw(80px)

    .group
      padding-top: vw(25px)
      padding-bottom: vw(25px)

      &:not(:last-child)
        border-bottom: 1px solid

      .item
        m-font-size(16, 20)
        position: relative
        display: flex
        display: flex
        align-items: center
        align-items: center
        width: 100%
        font-weight: 500

        &:not(:last-child)
          margin-bottom: vw(20px)

        .text
          flex: 1 1 0%
          margin-right: vw(30px)

        .icon
          //m-icon("panel-by", 30)
          flex: 0 0 vw(30px)
          margin-right: vw(10px)

          &.rosco
            m-icon("donut", 20)

        &.with-caret
          &:after
            display: block
            content: ""
            m-icon("arrow-right", 10)
            position: absolute
            right: vw(10)
</style>