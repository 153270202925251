<template>
  <div class="header">
    <div v-if="isMobile" class="menu-icon burger" @click="showPanel=true" />
    <left-panel v-if="isMobile" v-model="showPanel" />
    <!-- 
    <div
      v-if="isMobile"
      class="menu-icon personal"
      :class="user ? 'logged' : 'personal'"
      @click="goToDouglasProfiler"
    />
    -->
    <div class="logo" @click="goToDouglas" />
    <!-- WAIT LOGIN -->
    <!--div class="menu-icons">
      <div
        v-if="!isMobile"
        class="menu-icon personal"
        :class="user ? 'logged' : 'personal'"
        @click="goToDouglasProfiler"
      />
      <div class="menu-icon heart" @click="goToDouglasWishlist">
        <dot v-if="perfumesIds.length" :number="perfumesIds.length" :translate="true" />
      </div>
      <div class="menu-icon cart" @click="goToDouglasCart" />
    </div-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { STATIC_PATH_SETTING } from '../../settings'
import LeftPanel from './LeftPanel.vue'
//import Dot from './Dot.vue'
export default {
  components: {
    LeftPanel
    //Dot,
  },
  data(){
    return {
      showPanel:false
    }
  },
  computed: {
    ...mapGetters({ perfumesIds: 'profiler/perfumesIds', user: 'profiler/user' }),
  },
  methods: {
    goToDouglas() {
      window.open(STATIC_PATH_SETTING.DOUGLAS_WELCOME, '_self')
    },
    goToDouglasProfiler() {
      window.open(STATIC_PATH_SETTING.DOUGLAS_PROFILER, '_self')
    },
    goToDouglasWishlist() {
      window.open(STATIC_PATH_SETTING.DOUGLAS_WISHLIST, '_self')
    },
    goToDouglasCart() {
      window.open(STATIC_PATH_SETTING.DOUGLAS_CART, '_self')
    },
  },
}
</script>

<style lang="stylus" scoped>
$size = vw(40px)

.header
  position: relative
  display: flex
  flex: 0 0 $header-height
  align-items: center
  align-content: center
  padding-right: vw(50px)
  padding-left: vw(50px)
  width: 100%
  height: $header-height
  border-bottom: 1px solid #ccc
  background-color: #fff

  .mobile &
    flex: 0 0 $header-height-mobile
    padding: vw(10px) vw(20px)
    height: $header-height-mobile
    border-top: vw(10px) solid #C3EAE4
    border-bottom: none

.logo
  position: absolute
  top: 50%
  left: 50%
  width: vw(150px)
  height: vw(30px)
  background-image: url("~@/assets/douglas-logo.svg")
  background-position: center center
  background-size: contain
  background-repeat: no-repeat
  cursor: pointer
  transform: translate(-50%, -50%)

  .mobile &
    width: vw(110px)
    height: vw(20px)

.menu-icons
  display: flex
  align-items: center
  margin-left: auto

.menu-icon
  position: relative
  width: $size
  height: $size
  cursor: pointer

  &:not(:last-child)
    margin-right: vw(30px)

    .mobile &
      margin-right: vw(10px)

  &.personal
    m-icon("user", 20)

    .mobile &
      m-icon("user", 20)

  &.logged
    m-icon("login", 30)

    .mobile &
      m-icon("login", 30)
      flex: 0 0 vw(30px)

  &.heart
    m-icon("heart", 30)

    .mobile &
      m-icon("heart", 30)
      flex: 0 0 vw(30px)

    .number-badge
      top: vw(-5px)
      left: vw(-5px)

  &.cart
    m-icon("bag", 30)

    .mobile &
      m-icon("bag", 30)
      flex: 0 0 vw(30px)

  &.burger
    m-icon("menu", 80)

    .mobile &
      m-icon("menu", 30)
      flex: 0 0 vw(30px)
</style>