<template>
  <button
    class="next-button next-btn btn btn--tertiary btn--small has-icon"
    :class="{disabled:nextDisabled,hide:!visibleNextBtn}"
    @click="handleNext"
  >
    {{ $t("next") }}
    <div class="icon next" /> 
  </button>
</template>

<script>
import QueryStringMixin from '../../mixins/QueryStringMixin'
import StepHeaderMixin from '../../mixins/StepHeaderMixin'
import { ROUTER_NAMES } from '../../settings'
export default {
  mixins: [QueryStringMixin, StepHeaderMixin],
  computed: {
    visibleNextBtn() {
      return this.$route.name !== ROUTER_NAMES.RECOMMENDATIONS
    },
  },
  methods: {
    handleNext() {
      switch (this.$route.name) {
        case ROUTER_NAMES.SEARCH_FRAGRANCE:
          this.$routerPush({ name: ROUTER_NAMES.CONCEPTS, query: this.$route.query })
          break
        case ROUTER_NAMES.GENDER:
          this.$routerPush({ name: ROUTER_NAMES.RECOMMENDATIONS, query: this.$route.query })
          break
        case ROUTER_NAMES.CONCEPTS:
          this.$routerPush({ name: ROUTER_NAMES.GENDER, query: this.$route.query })
          break
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.next-button
  align-content: center
  font-size: 14px !important
  line-height: 22 !important
  transition: opacity 0.3s !important

  .mobile &
    font-size: 16px !important
    line-height: 26 !important

  &.hide
    display: none
    opacity: 0 !important
    cursor: default
    cursor: not-allowed
    pointer-events: none

  &.has-icon
    .mobile &
      padding: 0 !important

    &.back-btn
      padding-right: 1.75em
      padding-left: 0.5em

    &.next-btn
      padding-right: 0.5em
      padding-left: 1.75em
      color: #fff

      .icon
        &.next
          m-icon("arrow-right-white", 24)

        .mobile &
          display: none

  .icon
    &.back
      m-icon("arrow-right", 24)
      margin-right: 0.5em
      transform: rotate(180deg)

    &.next
      m-icon("arrow-right", 24)
      margin-left: 0.5em

    .mobile &
      display: none
      margin: 0
</style>