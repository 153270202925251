import Vuex from 'vuex'
import Vue from 'vue'
import setupRemoteModels from './remoteModels'

Vue.use(Vuex)

class Store extends Vuex.Store{
  constructor(options) {
    options = setupRemoteModels(options)
    super(options);
  }
}

export default {Store}