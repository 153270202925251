<template>
  <div itemscope itemtype="http://schema.org/PropertyValue" class="family-and-icon">
    <div class="icon">
      <div class="outer" :style="{background: family.color}" />
      <div class="inner" :style="{background: subfamily ? subfamily.color:family.color}" />
    </div>
    <meta itemprop="name" content="family">
    <div v-tooltip.top="label" itemprop="value" class="label">{{ label }}</div>
  </div>
</template>

<script>
import { VTooltip } from 'v-tooltip'
export default {
  name: 'FamilyWithIcon',
  components: {},
  directives: {
    tooltip: VTooltip,
  },
  props: ['family', 'subfamily'],
  computed: {
    label() {
      if (this.family.name) {
        if (this.subfamily) return this.family.name + ' / ' + this.subfamily.name
        return this.family.name
      } else return ''
    },
  },
  methods: {},
}
</script>

<style scoped lang="stylus">
.family-and-icon
  display: flex
  align-items: center
  cursor: pointer

  .icon
    position: relative
    margin-right: vw(5px)
    width: vw(27px)
    height: vw(27px)

    .mobile &
      margin-right: vw(5px)
      width: vw(30px)
      height: vw(30px)  
    .outer,
    .inner
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

    .outer
      width: vw(25px)
      height: vw(25px)
      border-radius: 50%

    .inner
      width: vw(15px)
      height: vw(15px)
      border: solid 1px #fff
      border-radius: 50%

    .mobile &
      .outer
        width: vw(20px)
        height: vw(20px)

      .inner
        width: vw(12px)
        height: vw(12px)

  .label
    flex: 1 1 0%
    m-ellipsis(100%)
    m-font-size(12, 16)
    align-items: center

    .mobile &
      m-font-size(11, 14)
</style>