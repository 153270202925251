<template>
  <div class="next-back-mobile-footer">
    <back-button />
    <next-button />
  </div>
</template>

<script>
import BackButton from './buttons/BackButton.vue'
import NextButton from './buttons/NextButton.vue'
export default {
  components: { NextButton, BackButton },
}
</script>

<style lang="stylus" scoped>
.next-back-mobile-footer
  display: flex
  justify-content: center
  padding: vw(10px) vw(20px)
  height: vw(70px)
  background-color: #fff

  .mobile &
    border: 1px solid #E6E6E6

  .btn
    flex: 1 1 0%
    height: vw(50px)
</style>