import { api } from '../../services'

export default {
  remoteModels: {
    /** Request to all Puig database. Without filtering by hostId */
    search: {
      get: ({ rootState }, search) => api.getPerfumeByText(rootState.i18n.lang, search),
      getById: ({ rootState }, id) => api.getPerfumeById(rootState.i18n.lang, id),
      lang: rootState => rootState.i18n.lang,
      isList: true,
    },
    /** Request filtered by hostId */
    reco: {
      get: ({ rootState }, perfumes, gender, brand, concepts, families, numReco) =>
        api.getSimilarPerfumes(
          rootState.i18n.lang,
          perfumes,
          { gender, brand, families, concepts },
          numReco ?? 12
        ),
      getById: ({ rootState }, id) => api.getDouglasPerfumeById(rootState.i18n.lang, id),
      lang: rootState => rootState.i18n.lang,
      isList: true,
    },
  },
  mutations: {},
  actions: {},
}
