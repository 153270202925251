<template>
  <div class="steps">
    <div
      v-for="(step, index) in stepsBooleans"
      :key="index"
      class="step"
      @click="handleClick(step,index)"
    >
      <div class="container" :class="{ selected: step.isSelected,'current':index===selected }" />
    </div>
  </div>
</template>
<script>
import StepHeaderMixin from '../../mixins/StepHeaderMixin'
export default {
  mixins: [StepHeaderMixin],
  computed: {
    stepsBooleans() {
      return this.steps.map((step, index) => ({ ...step, isSelected: index <= this.selected }))
    },
    total() {
      return this.steps.length
    },
  },
  methods: {
    handleClick(step) {
      if (!step.disabled) this.$routerPush({ name: step.routeName, query: this.$route.query })
    },
  },
}
</script>
<style lang="stylus" scoped>
.steps
  display: flex
  width: 100%
  height: vh(10px)

  .mobile &
    height: vw(10px)

  .step
    flex: 1 1 100%
    margin: 0 vw(3px)
    height: 100%
    background-color: #ddd

    .container
      width: 0
      height: 100%
      background-color: $primary-theme-color
      opacity: 0
      transition: all 0.5s

      &.selected
        width: 100%
        opacity: 1

      &.current
        background-color: $primary-theme-color-dark
</style>
