import {isMobileOnly, isTablet} from 'mobile-device-detect'
import {isIE, isEdge, isSafari} from 'mobile-device-detect'

let deviceType = 'desktop'
if(isTablet) deviceType = 'tablet'
else if (isMobileOnly) deviceType = 'mobile'


export default deviceType

export let isBadImgLoaderBrowser =  isIE || isEdge || isSafari
