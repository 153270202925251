<template>
  <transition name="fade">
    <div
      v-if="open"
      class="basic-modal"
    >
      <div v-click-outside="handleClose" class="basic-modal-container">
        <div class="icon close" @click="$emit('close', false)" />
        <div v-if="showHeader" class="header">
          <slot name="header">
            <div class="defaut-header">
              <div class="header-title">{{ title }}</div>      
            </div>
          </slot>
        </div>
        <div class="body">
          <slot />
        </div>
        <div class="footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
  directives: {
    ClickOutside: ClickOutside,
  },
  model: {
    prop: 'open',
    event: 'close',
  },
  props: {
    title: {
      type: String,
    },
    open: {
      type: Boolean,
      required: true,
    },
    showHeader:{
      type:Boolean,
      default:true
    }
  },
  methods: {
    handleClose() {
      console.error('Basic Modal not working vue-click-outside')
      //this.$emit('close', false)
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-modal
  position: fixed
  top: 0
  left: 0
  z-index: 100
  display: flex
  width: 100vw
  height: 100vh
  background-color: rgba(#000, 0.5)

  &.fade-enter-active,
  &.fade-leave-active
    transition: opacity 0.5s

  &.fade-enter,
  &.fade-leave-to
    opacity: 0
  .basic-modal-container
    position: relative
    margin: auto
    background-color: #fff
    max-height: 80vh
    display: flex
    flex-direction: column
    .mobile &
      //padding: vw(20px)
      width: 90%
      
    .icon
      position: absolute
      top: vw(15px)
      right: vw(15px)

      &.close
        m-icon("close", 30)
        cursor: pointer

  
    .header
      display: flex
      justify-content: flex-end
      margin-bottom: vw(20px)

      .defaut-header
        min-height: vh(30px)
        width: 100%

        .mobile &
          min-height: vw(30px)

        .header-title
          m-font-size(18, 28)
          margin-right: vw(30px)
          font-weight: 600
    .body
      overflow-y: auto
      height: 100%
</style>