<template>
  <div class="stepper">
    <div
      v-for="(step,index) in steps"
      :key="index"
      :class="{disabled:step.disabled,selected:index===selected}"
      class="step"
      @click="handleClick(step,index)"
    >
      <div class="step-number">
        <div class="number">{{ index +1 }}</div>
      </div>
      <div class="step-text">{{ step.title }}</div>
    </div>
  </div>
</template>

<script>
import StepHeaderMixin from '../../mixins/StepHeaderMixin'
export default {
  mixins: [StepHeaderMixin],
  props: {
    nextEnable: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    handleClick(step) {
      if (!step.disabled && this.$route.name!==step.routeName) this.$routerPush({ name: step.routeName, query: this.$route.query })
    },
  },
}
</script>

<style lang="stylus" scoped>
$box-size = vh(30px)

.stepper
  display: flex
  align-items: center
  height: 100%

  .step
    m-font-size(14, 22)
    display: flex
    align-items: center
    padding: vh(15px) 0
    height: 100%
    border-bottom: 4px solid transparent
    font-weight: 600
    cursor: pointer

    &:not(:last-child)
      margin-right: vw(30px)

    &.selected
      border-bottom-color: $primary-theme-color

    &.disabled
      color: #ddd
      pointer-events: none

      .step-number
        background-color: #ddd
        color: #fff

    .step-number
      display: flex
      justify-content: center
      align-items: center
      margin-right: vw(10px)
      width: $box-size
      height: $box-size
      border-radius: 2px
      background-color: $primary-theme-color

      .number
        margin: auto
</style>