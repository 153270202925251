const NOVELTY_ID = 'N'
const CLASSIC_ID = 'C'

export default {
  concepts: [
    {
      id: 15,
      name: 'concept_leicht',
      trackName: 'leicht',
    },
    {
      id: 2,
      name: 'concept_intensiv',
      trackName: 'intensiv',
    },
    {
      id: 31, // affirmed
      name: 'concept_alltag',
      trackName: 'alltag',
    },
    {
      id: 30, // noticeable
      name: 'concept_anlass',
      trackName: 'anlass',
    },
    {
      id: CLASSIC_ID, //classic
      name: 'concept_klassiker',
      trackName: 'klassiker',
    },
    {
      id: NOVELTY_ID, //novetly
      name: 'concept_neuheit',
      trackName: 'neuheit',
    },
    {
      id: 100, // leaf
      name: 'concept_beruhigend',
      trackName: 'beruhigend',
    },
    {
      id: 32, // seductive
      name: 'concept_betörend',
      trackName: 'betörend',
    },
  ],
  filters: {
    novelty: NOVELTY_ID,
    classic: CLASSIC_ID,
  },
}
