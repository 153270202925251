import EventTracker from '@bestiario/puig-event-tracker/browser'
import { v4 as uuidv4 } from 'uuid'

export const eventTracker = new EventTracker({
  appId: process.env.VUE_APP_ID,
  apiURL: process.env.VUE_APP_API_URL,
  uuid: `GUEST:${uuidv4()}`
})

export const EventTrackerPlugin = {
  install (Vue) {
    Vue.prototype.eventTracker = eventTracker
  }
}

export function getEventTrackerPlugin (customerId) {
  if (customerId)
    eventTracker.uuid = customerId
  return {
    install (Vue) {
      Vue.prototype.eventTracker = eventTracker
    }
  }
}
