import ROUTER_NAMES from './RouterNames'
import QUERY_STRING_SETTING from './QueryStringSetting'
/**
 * Setting for routing. If route name is not configured, then everithing is enable.
 * see import('../mixins/QueryStringMixin.js') for query string values
 */
export default {
  [ROUTER_NAMES.SEARCH_FRAGRANCE]: {
    enableQs: [QUERY_STRING_SETTING.SEARCH, QUERY_STRING_SETTING.PERFUMES],
  },
  [ROUTER_NAMES.CONCEPTS]: {
    enableQs: [
      QUERY_STRING_SETTING.SEARCH,
      QUERY_STRING_SETTING.PERFUMES,
      QUERY_STRING_SETTING.CONCEPTS,
    ],
  },
  [ROUTER_NAMES.RECOMMENDATIONS]: {
    enableQs: [
      QUERY_STRING_SETTING.SEARCH,
      QUERY_STRING_SETTING.PERFUMES,
      QUERY_STRING_SETTING.CONCEPTS,
    ],
  },
}
