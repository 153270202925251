<template>
  <div class="fragrance-component">
    <div class="fragrance-image" :style="'background-image: url(' + perfume.imageUrl + ')'" />
    <div class="fragance-info">
      <div class="fragance-name">{{ perfume.brand.name }}</div>
      <div class="fragance-brand-container">
        <div :class="['fragance-brand']">{{ perfume.name }}</div>
      </div>
      <families-with-icon :family="perfume.family" :subfamily="perfume.secondaryFamily" />
    </div>
    <div class="icon remove" :class="{disabled:!removable}" @click="$emit('remove')" />
  </div>
</template>

<script>
import FamiliesWithIcon from '../FamiliesWithIcon.vue'
export default {
  components: { FamiliesWithIcon },
  props: {
    perfume: {
      type: Object,
    },
    removable: {
      type: Boolean,
    },
  },
}
</script>

<style lang="stylus" scoped>
.fragrance-component
  display: flex
  align-items: center
  padding: vw(16px) vw(26px)
  border-bottom: 1px solid #E6E6E6

  .icon
    align-self: flex-start
    transition: opacity 0.3s ease-in-out

    &.remove
      m-icon("bin", 24)
      cursor: pointer

    &.disabled
      opacity: 0.5
      cursor: default
      cursor: not-allowed
      pointer-events: none

.fragrance-image
  margin-right: vw(20px)
  width: vw(70px)
  height: vw(70px)
  background-position: center center
  background-size: contain
  background-repeat: no-repeat

.fragance-info
  display: flex
  flex: 1 1 0%
  flex-direction: column
  justify-content: space-between
  justify-content: space-around
  justify-content: space-evenly
  align-self: stretch
  overflow: hidden

.fragance-name
  m-font-size(12, 16)
  margin-bottom: vw(5px)
  text-transform: uppercase
  font-weight: 900

.fragance-brand-container
  display: flex
  align-items: center
  margin-bottom: vw(5px)

.fragance-brand
  m-ellipsis(100%)
  m-font-size(12, 16)

.product-separator
  margin-right: vw(10px)
  margin-left: vw(10px)
  width: 1px
  height: 75%
  background-color: #000

.product-line
  m-ellipsis(100%)
  m-font-size(12, 16)

.fragance-another-thing
  m-font-size(12, 16)
  margin-bottom: vw(5px)
</style>