import { ROUTER_NAMES } from '../settings'

export default {
  computed: {
    selected() {
      return this.stepsConfig.findIndex(step => step.routeName === this.$route.name)
    },
    currentStep() {
      return this.steps.find(step => step.routeName === this.$route.name)
    },
    stepsConfig() {
      return [
        {
          title: this.$t('stepper_title_1'),
          disabled: false,
          routeName: ROUTER_NAMES.SEARCH_FRAGRANCE,
        },
        {
          title: this.$t('stepper_title_2'),
          disabled: true,
          routeName: ROUTER_NAMES.CONCEPTS,
        },

        {
          title: this.$t('stepper_title_3'),
          disabled: true,
          routeName: ROUTER_NAMES.GENDER,
        },
        {
          title: this.$t('stepper_title_4'),
          disabled: true,
          routeName: ROUTER_NAMES.RECOMMENDATIONS,
        },
      ]
    },
    steps() {
      return this.stepsConfig.map((step, index) => {
        return {
          ...step,
          disabled: index === this.selected + 1 ? !this.nextEnable : index > this.selected + 1,
        }
      })
    },
    nextDisabled() {
      switch (this.$route.name) {
        case ROUTER_NAMES.SEARCH_FRAGRANCE:
          return this.qsSelectedPerfumes.length < 2
        case ROUTER_NAMES.CONCEPTS:
          return this.qsConcepts.length < 1 // && this.qsSelectedPerfumes.length < 3
        case ROUTER_NAMES.GENDER:
          return !this.qsGender
        default:
          return true
      }
    },
  },
}
