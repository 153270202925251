/** @enum {string} */
const ROUTER_NAMES = {
  WELCOME: 'Welcome',
  SEARCH_FRAGRANCE: 'Search Fragrance',
  CONCEPTS: 'Concepts',
  GENDER: 'Gender',
  PERFUME: 'Perfume',
  RECOMMENDATIONS: 'Recommendations',
  LOGIN: 'Login',
  NOT_FOUND: 'Not Found',
  MAINTENANCE: 'Maintenance'
}

export default ROUTER_NAMES
