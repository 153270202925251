import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import AsyncComputed from 'vue-async-computed'
import DeviceDetect from './mixins/DeviceDetect'
import i18n from './i18n'
import queryStringPlugin from './plugins/QueryStringPlugin'
import remoteComputed from './store/lib/remoteComputed'
import RouterMethodsMixin from './mixins/RouterMethodsMixin'
import { getEventTrackerPlugin } from '@/services/track'
import { getCustomerId } from '@/services/Api'

function handleLogin() {
  return new Promise((resolve) => {
    const params = new URLSearchParams(window.location.search)
    if (params.has('utoken')) {
      const utoken = params.get('utoken')
      getCustomerId(utoken).then((res) => {
        const customerId = res.customerId
        resolve({ customerId, utoken})
      }).catch(() => {
        resolve({ utoken })
      })
    }
    else {
      resolve({})
    }
  })
}

Vue.mixin(DeviceDetect)
Vue.use(remoteComputed)
Vue.mixin(i18n)
Vue.config.productionTip = false
// Vue.use(AsyncComputed)
Vue.use(queryStringPlugin)
Vue.mixin(RouterMethodsMixin)

handleLogin().then(({ customerId, utoken }) => {
  Vue.use(getEventTrackerPlugin(customerId))
  utoken && store.dispatch('setUtoken', utoken)
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})

