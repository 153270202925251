import Vue from 'vue'
import VueRouter from 'vue-router'
import { langs } from '../i18n'
import { ROUTER_NAMES } from '../settings'
import store from '../store'
import stackMiddleware from './midleware/stackMiddleware'
//import trackRoutingMiddleware from './midleware/trackingMiddleware'

Vue.use(VueRouter)

const routesChild = [
  {
    path: '/',
    name: ROUTER_NAMES.WELCOME,
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/WelcomeView.vue'),
  },
  {
    path: 'search',
    name: ROUTER_NAMES.SEARCH_FRAGRANCE,
    component: () => import('../views/SearchFragranceView.vue'),
  },
  {
    path: 'concepts',
    name: ROUTER_NAMES.CONCEPTS,
    component: () => import('../views/SearchFragranceView.vue'),
    props: {
      isConcepts: true,
    },
  },
  {
    path: 'gender',
    name: ROUTER_NAMES.GENDER,
    component: () => import('../views/GenderView.vue'),
    props: {
      isConcepts: true,
    },
  },
  {
    path: 'perfume/:id',
    name: ROUTER_NAMES.PERFUME,
    component: () => import('../views/PerfumeView.vue'),
    props: true,
  },
  {
    path: 'reco',
    name: ROUTER_NAMES.RECOMMENDATIONS,
    component: () => import('../views/RecommendationView.vue'),
    props: {
      isConcepts: true,
    },
  },
  {
    path: 'login',
    name: ROUTER_NAMES.LOGIN,
    component: () => import('../views/LoginView.vue'),
  },
  
  {
    path: 'maintenance',
    name: ROUTER_NAMES.MAINTENANCE,
    component: () => import('../views/MaintenanceView.vue'),
  },
  {
    path: '*',
    name: ROUTER_NAMES.NOT_FOUND,
    component: () => import('../views/NotFound.vue'),
  },
]
function getLocaleRegex() {
  let reg = ''
  langs.forEach((locale, index) => {
    reg = `${reg}${locale}${index !== langs.length - 1 ? '|' : ''}`
  })
  return `(${reg})`
}
const locationFromDomExtension = window.location.origin.split('.').pop()
const routes = [
  {
    path: `/:locale${getLocaleRegex()}?`,
    component: () => import('../views/Locate.vue'),
    beforeEnter(to, from, next) {
      const locale = to.params.locale
      if (locale) store.commit('setLanguage', locale)
      else if (langs.includes(locationFromDomExtension))
        store.commit('setLanguage', locationFromDomExtension)
      else store.commit('setLanguage', process.env.VUE_APP_DEFAULT_LANG)
      if (!langs.includes(locale)) return next()
      return next()
    },
    children: routesChild,
  },
]
const router = new VueRouter({
  routes,
  base: process.env.BASE_URL,
  mode: 'history',
})

router.beforeEach(stackMiddleware)
// router.beforeEach(trackRoutingMiddleware)
/**
 * Configuration for always keep query string in every view. It is configured by ROUTING_SETTING
 */
/*router.beforeEach((to, from, next) => {
  if (!from || !from.name) return next()
  if (to.name === from.name) return next()
  const config = ROUTING_SETTING[to.name]
  const queryFrom = from.query
  var newQuery = queryFrom
  if (config) {
    newQuery = config.enableQs.reduce((result, qs) => {
      if (queryFrom[qs]) result[qs] = queryFrom[qs]
      return result
    }, {})
  }
  if (isEquivalent(newQuery, to.query)) {
    console.log('IS EQUIVALENT')
    return next()
  }

  next({ ...to, query: newQuery, replace: true })
})*/

export default router
