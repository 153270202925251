<template>
  <footer class="footer">
    <a 
      v-for="link in links" 
      :key="link.name" 
      :href="link.href" 
      target="_blank"
    >{{ link.name }}</a> 
    <a @click="handleOpenCookies">{{ $t('cookies_polices') }}</a>
  </footer>
</template>
<script>
import QueryStringMixin from '../mixins/QueryStringMixin'
export default {
  mixins:[QueryStringMixin],
  data(){
    return {
      openModal:false
    }
  },
  computed:{
    links(){
      return [{
        name:this.$t('data_protection'),
        href:'https://www.douglas.de/de/cp/helpdataprotection/help-dataprotection'
      },
      {
        name: this.$t('help_imprint'),
        href:'https://www.douglas.de/de/cp/helpimprint/help-imprint'
      }]
    }
  },
  methods:{
    handleOpenCookies(){
    // eslint-disable-next-line
    if(UC_UI)
      // eslint-disable-next-line
      UC_UI.showFirstLayer()
    else
      console.error('[USER CENTRIC] user centric variable not found')
    }
  },
  
}
</script>
<style lang="stylus" scoped>
.footer
  display: flex
  align-items: center
  background-color: #f2f2f2
  padding vw(3px)
  > a
    margin-right: vw(30px)
    letter-spacing: 1px;
    m-font-size(13,20)
    &:first-child
      margin-left: vw(50px)
</style> 