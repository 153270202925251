<template>
  <basic-modal
    class="selected-perfumes-modal"
    :open="qsIsSelectedOpen"
    :title="titleModal"
    @close="qsIsSelectedOpen=false"
  >
    <selected-fragrances />
    <template #footer> <button class="btn--ghost custom-btn" @click="handleRemoveAll">{{ $t("search_fragrance_remove") }}</button></template>
  </basic-modal>
</template>

<script>
import QueryStringMixin from '../mixins/QueryStringMixin'
import { ROUTER_NAMES } from '../settings'
import BasicModal from './basic/BasicModal.vue'
import SelectedFragrances from './SelectedFragrances'
export default {
  components: { BasicModal, SelectedFragrances },
  mixins: [QueryStringMixin],
  computed: {
    titleModal() {
      return `${this.$t('search_fragrance_quantity')} (${this.quantity})`
    },
    quantity() {
      return this.qsSelectedPerfumes.length
    },
  },
  methods: {
    handleRemoveAll() {
      this.$pushHistory({
        name: ROUTER_NAMES.SEARCH_FRAGRANCE,
        query: { ...this.$route.query, qsSelectedPerfumes: [], qsIsSelectedOpen: false },
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
$secondary-color = #915

.selected-perfumes-modal
  width: 100%
  height: 100%

  .list-complete-item
    opacity: 1
    transition: all 0.8s ease-in-out
    transform: translateX(0)

  .list-complete-enter,
  .list-complete-leave-to
    opacity: 0
    transform: translateX(100%)

  >>> .selected-fragrances-list
    .fragrance-component
      padding: 0
      border: none

      &:not(:last-child)
        margin-bottom: vw(30px)

  >>> .body
    margin-bottom: vw(40px) !important
    max-height: 70vh !important

  .custom-btn
    m-font-size(18, 28)
    position: absolute
    bottom: 0
    left: 0
    padding: 0
    width: 100%
    height: vw(40px)
    border: none
    border-top: 1px solid #e6e6e6
    color: #000
    text-decoration: underline
</style>
