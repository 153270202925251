export default {
  welcome_to_bestiario: 'This is a new Bestiario_ project',
  concept_info:
    'If you only selected one frgrance, our algorithm needs you to select at least 3 descriptions',
  next: 'Next',
  stepper_title_1: 'Search your fragrances',
  stepper_title_2: 'Describe the ideal fragrance',
  stepper_title_3: 'Select gender',
  stepper_title_4: 'Get recommendations',
  select_all: 'All',
  male: 'Male',
  female: 'Female',
  unisex: 'Unisex',
  accept: 'Accept',
  cancel: 'Cancel',
  back: 'Back',
  your_profile: 'your olfactive profile',
  welcome_title: 'Welcome to Parfum-Finder!',
  welcome_subtitle:
    'Erstelle dein persönliches Duftprofil, um das perfekte Parfum zu finden – für dich selbst oder als Geschenk! Wonach suchst du?',
  welcome_question: '',
  welcome_for_me: 'DUFTGESCHENK',
  welcome_for_gift: 'DUFT FÜR MICH',
  search_view_title: 'Search for fragrances',
  search_view_subtitile:
    'Tipp: Hier können Düfte oder Marken dabei sein, die du du bereits besitzt, ausprobiert hast oder von Freunden kennst.',
  concept_leicht: 'leicht',
  concept_intensiv: 'intensiv',
  concept_alltag: 'alltag',
  concept_anlass: 'anlass',
  concept_klassiker: 'klassiker',
  concept_neuheit: 'neuheit',
  concept_beruhigend: 'beruhigend',
  concept_betörend: 'betörend',
  search_view_info: 'Für eine möglichst treffsichere Empfehlung wähle 2 bis 3 Düfte aus.',
  search_fragrance_info:
    'Falls du zuvor nur einen Duft angegeben hast, brauchen wir hier mindestens drei Angaben von dir.',
  search_view_tipp:
    'Tipp: Hier können Düfte oder Marken dabei sein, die du du bereits besitzt, ausprobiert hast oder von Freunden kennst.',
  search_fragrance_quantity: 'Ausgewählte Düfte',
  search_fragrance_remove: 'Liste löschen',

  concepts_title: 'Welche dieser Begriffe beschreibt den gwünschten Duft am besten?',
  recommendation_you_love_perfumes_of_the: 'you love perfumes of the ',
  recommendation_you_love_perfumes_of_the_plural: 'you love perfumes of the',
  recommendation_and: 'and',
  recommendation_desc_family: 'family',
  recommendation_desc_families: 'families',
  recommendation_you_also_love_plural: 'you also love nuances of the',
  recommendation_you_also_love: 'you also love nuances of the',
  recommendation_desc_sub_family: 'subfamily',
  recommendation_desc_sub_families: 'subfamilies',
  recommendation_top_6_title: 'Top 6 recommended according to your profile',
  recommendation_top_6_sub_title: 'Recommendations based on your profile settings',
  recommendation_edit_preferences: 'Edit Preferences',
  recommendation_your_favorite_ings: 'Your Favorite Ingredients',
  recommendation_more_reco_all_reco: 'All recommendations',
  recommendation_more_reco_title: 'More recommendations according to your profile',
  perfume_view_predominant_ingredient: 'Predominant ingredients of this fragrance',
  perfume_view_perfume_similar_ingredients_title: 'Perfumes with similar ingredients',
  perfume_view_perfume_similar_ingredients_subtitile:
    'We have created an olfative profile according to your selection in order to recommend some fragrances.',
  breadcumbs_header_your_recommendation: 'Your recommendations',

  not_loged_modal_title: 'Angaben bearbeiten?',
  not_loged_modal_description: 'Wenn du jetzt zum Anfang zurückkehrst, gehen deine Angaben und Empfehlungen verloren. Logg dich ein, damit deine Angaben gespeichert werden!',

  not_loged_modal_title_wishlist: 'Du bist nicht eingeloggt!',
  not_loged_modal_description_wishlist: 'Um diese Produkte in deine Wunschliste aufzunehmen, musst du eingeloggt sein.',

  login_welcome: 'Welcome to Douglas',
  login_already_douglas: 'I am already a Douglas customer',
  login_username: 'E-mail address',
  login_password: 'Password',
  login_password_forgot: 'Forgot Password?',
  login_stay_logged: 'stay logged in',
  login_log_in_button: 'Log in',
  login_firstName: 'Fist name',
  login_surname: 'Surname',
  login_birth: 'Date of birth',
  login_choose_gender: 'Please choose',
  login_male: 'Man',
  login_female: 'Mrs',
  login_email: 'E-mail address',
  login_username_not_email: 'Incorrect format for e-mail',
  login_username_required: 'E-mail required',
  login_password_required: 'Password required',
  login_firstName_required: 'First name required',
  login_surname_required: 'Surname required',
  login_birth_incorrect_format: 'Birthday format incorrect',
  login_birth_required: 'Birthday required',
  login_email_required: 'E-mail required',
  login_passwordSignup_required: 'Password required',
}
