export default {
  methods: {
    $routerPush(obj) {
      return this.$router.push({
        query: this.$route.query,
        params: this.$route.params,
        ...obj,
      })
    },
  },
}
