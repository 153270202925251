export default {
  queryString: {
    qsSearch: 's',
    qsSelectedPerfumes: {
      type: Array,
      queryString: 'p',
    },
    qsConcepts: {
      type: Array,
      queryString: 'c',
    },
    qsGender: 'g',
    qsGenderSubTable: 'gtable',
    qsBrand: 'b',
    _qsForMe: {
      type: String,
      queryString: 'm',
    },
    qsIsSelectedOpen: {
      type: Boolean,
      queryString: 'open',
    },
    qsCallback: {
      queryString: 'callback',
      type: String,
    },
    qsAddWishlist: {
      queryString: 'addW',
      type: Boolean,
    },

  },
  computed: {
    qsForMe: {
      get() {
        if (this._qsForMe === 'FM') return true
        else if (this._qsForMe === 'FG') return false
        return null
      },
      set(boolean) {
        if (boolean) this._qsForMe = 'FM'
        else this._qsForMe = 'FG'
      },
    },
  },
}
