import axios from 'axios'
import queryString from 'query-string'
import { CONCEPT_SETTING } from '../settings'
const CancelToken = axios.CancelToken
let cancels = {}
function parseQS(obj) {
  return queryString.stringify(obj, {
    arrayFormat: 'comma',
  })
}
function cancelRequest(id) {
  if (cancels[id]) cancels[id]()
  cancels[id] = null
}
let instance = axios.create({
  baseURL: '',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})
let instanceWithoutHostId = axios.create({
  baseURL: '',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

let cancelableInstance = axios.create({
  baseURL: '',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(config => {
  config.params = Object.assign(config.params || {}, {
    hostId: process.env.VUE_APP_HOST_ID,
  })
  return config
})

cancelableInstance.interceptors.request.use(config => {
  /// CANCELABLE
  config.params = Object.assign(config.params || {}, {
    hostId: process.env.VUE_APP_HOST_ID,
  })
  let reqId = config.url.split('?')[0]
  cancelRequest(reqId)
  config.cancelToken = new CancelToken(c => (cancels[reqId] = c))
  return config
})
cancelableInstance.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (axios.isCancel(error)) console.log('Request canceled', error.message)
    else throw error
  }
)

/**
 * @return {Promise<any[]>}
 */
export async function getConcepts(lang, gender) {
  //console.warn('%c[getConcepts NOT IMPLEMENTED]', 'color:red')
  //let path = `/nocibe/concepts/`
  lang
  gender
  const path = '/concepts/'
  cancelableInstance
    .get(process.env.VUE_APP_API_URL + path, { params: { lang, gender } })
    .then(response => response.data)
}

export async function getCustomerId(utoken) {
  const res = await axios.get(process.env.VUE_APP_API_URL + `/user/profile?utoken=${utoken}`, )
  return res.data
}

/**
 * Get perfume by id without douglas product feed (host id)
 * @param {number} perfumeId
 * @param {number} configId
 * @param {string} lang
 */
export function getPerfumeById(lang, perfumeId) {
  const path = `/perfumes/${perfumeId}`
  return instanceWithoutHostId
    .get(process.env.VUE_APP_API_URL + path, { params: { lang } })
    .then(response => response.data)
}

/**
 * Get perfume by id with Douglas Host Id
 * @param {number} perfumeId
 * @param {number} configId
 * @param {string} lang
 */
export function getDouglasPerfumeById(lang, perfumeId) {
  const path = `/perfumes/${perfumeId}`
  return instance
    .get(process.env.VUE_APP_API_URL + path, { params: { lang } })
    .then(response => response.data)
}

export function getBrands() {
  const path = `/brands/`
  return instance.get(process.env.VUE_APP_API_URL + path).then(response => response.data)
}

/**
 * @param {}
 * @param {string} search search term
 */
export function getPerfumeByText(lang, search) {
  let path = process.env.VUE_APP_API_URL + `/v7/search`
  // let path = `http://localhost:3001/api/search`
  return instanceWithoutHostId
    .get(path, {
      params: {
        // strategy:"douglas_profiler",
        q: search,
        lang: lang,
        // "excluded.brands": process.env.VUE_APP_EXCLUDE_BRANDS,
        // "excluded.subBrands" : process.env.VUE_APP_EXCLUDE_SUB_BRANDS,
      },
    })
    .then(res => res.data.data??res.data)
}

/**
 *
 * @param {Array<string>} ids
 */
export function getPerfumesByIds(lang, ids) {
  return instance
    .get(
      process.env.VUE_APP_API_URL + `/perfumes/by_ids?allPresent=true&sortByEntryOrder=true&perfumeIds=${ids.join(',')}`,
      {
        params: { lang },
      }
    )
    .then(res => res.data)
}

/**
 *
 * @param {Array} perfume Object with id or Array of Object with id
 * @param {{
 *    concepts?:Array
 *    gender?:('M' |'F' |'U')[]
 *    brand?:string[]
 *    families:number[]
 * }} filters
 */
export function getSimilarPerfumes(lang, perfumes, filters, numRecos = 12) {
  const { brand, gender, families, concepts, ...otherFilters } = filters
  const realConcepts = []
  const filterConceptParams = {}
  const settingFiltersKeys = Object.keys(CONCEPT_SETTING.filters)
  console.log(concepts)
  concepts.forEach(concept => {
    const keyFilter = settingFiltersKeys.find(
      key => CONCEPT_SETTING.filters[key].toString() === concept.toString()
    )
    if (keyFilter) filterConceptParams[keyFilter] = true
    else realConcepts.push(concept)
  })
  let filterParams = parseQS({
    ...otherFilters,
    ...filterConceptParams,
    conceptsAgg: realConcepts,
    'included.gender': gender?.join(','),
    'included.brands': brand?.join(','),
    // 'excluded.brands': process.env.VUE_APP_EXCLUDE_BRANDS,
    // 'excluded.subBrands' : process.env.VUE_APP_EXCLUDE_SUB_BRANDS,
    'included.families': families?.join(','),
    perfumes,
    "limit": numRecos,
    lang,
    light:true
  })
  if (perfumes.length === 0) return Promise.resolve([])
  // let path = `http://localhost:3001/api/reco?${filterParams}&strategy=douglas_profiler`
  let path = process.env.VUE_APP_API_URL + `/v7/reco?${filterParams}`
  return cancelableInstance.get(path).then(response => {
    //return response.data.data
    return getPerfumesByIds(
      lang,
      response.data.data.map(e => e.id)
    )
  })
}
export function getFamilies(lang) {
  return instance
    .get(process.env.VUE_APP_API_URL + '/families/', {
      params: { lang },
    })
    .then(res => res.data)
}
export function getUserData() {
  return instance.get(process.env.VUE_APP_API_URL + '/extern/userId/').then(res => res.data)
}
/**
 *
 * @param {string} userId user id
 * @returns {Promise<string>}
 */
function getTokenFromId(userId) {
  return axios.get(process.env.VUE_APP_API_URL + `/extern/${userId}/jwt`).then(res => res.data)
}
export async function login(email, password) {
  return axios
    .post(process.env.VUE_APP_API_URL + '/extern/login/', { email, password })
    .then(res => getTokenFromId(res.data))
    .then(token => {
      /** Set cookie for all subdomains*/
      if (process.env.NODE_ENV === 'production') {
        const domain = document.location.host
        .split('.')
        .slice(-2)
        .join('.')
        document.cookie = `gjwt=${token};domain=.${domain}`
      }
      else document.cookie = `gjwt=${token}`
      return getUserData()
    })
}
export default instance
