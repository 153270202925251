import { api } from '../../services'
import { eventTracker } from '@/services/track'

/**
 * @typedef ProfilerState
 * @property {string[]} perfumes
 * @property {string[]} concepts
 * @property {'M' | 'F' | 'U'} gender
 * @property {boolean} isFetching
 * @property {any} user
 */
const SET_PERFUMES = 'SET_PERFUMES'
const SET_USER = 'SET_USER'
const SET_FETCHING = 'SET_FETCHING'
const SET_TO_DEFAULT = 'SET_TO_DEFAULT'
/**@type {ProfilerState} */
const defaultState = {
  perfumes: [],
  concepts: [],
  gender: null,
  user: null,
  isFetching: false,
}
export default {
  state: {
    ...defaultState,
  },
  namespaced: true,
  mutations: {
    /**
     *
     * @param {ProfilerState} state state
     * @param {string[]} perfumes
     */
    [SET_PERFUMES](state, perfumes) {
      state.perfumes = perfumes.map(p => p.toString())
    },
    /**
     *
     * @param {ProfilerState} state state
     * @param {any} user
     */
    [SET_USER](state, user) {
      this.eventTracker.emitEvent('LOGIN', { user: JSON.stringify(user)})
      state.user = user
    },
    /**
     *
     * @param {ProfilerState} state state
     * @param {boolean} isFetching
     */
    [SET_FETCHING](state, isFetching) {
      state.isFetching = isFetching
    },
    /**
     *
     * @param {ProfilerState} state
     */
    [SET_TO_DEFAULT](state) {
      Object.keys(defaultState).map(key => {
        state[key] = defaultState[key]
      })
    },
  },
  actions: {
    /**
     * @param {import('vuex').ActionContext<ProfilerState>} context
     */
    init(context) {
      context.commit(SET_FETCHING, true)
      api
        .getUserData()
        .then(user => {
          context.commit(SET_USER, user)
        })
        .catch(() => {
          // if user is not null, it mean that user is logged before, so we need to remove state
          if (context.state.user !== null) {
            context.commit(SET_TO_DEFAULT)
          } else {
            context.commit(SET_USER, null)
          }
        })
        .finally(() => {
          context.commit(SET_FETCHING, false)
        })
    },
    /**
     *
     * @param {import('vuex').ActionContext<ProfilerState>} context
     * @param {string} perfume string
     */
    pushPerfume(context, perfume) {
      const state = context.state
      const index = state.perfumes.indexOf(perfume.toString())
      if (index > -1)
        return console.warn('[Bestiario warn] perfume', perfume, 'is already on user perfumes')
      eventTracker.emitEvent('ADD_TO_PROFILE', { id: parseInt(perfume.id), type: 'PERFUME', resultingProfile: {
        perfumes: [...state.perfumes.map(p => parseInt(p)), parseInt(perfume)],
        concepts: state.concepts,
      }})
      context.commit(SET_PERFUMES, [...state.perfumes, perfume])
    },
    /**
     *
     * @param {import('vuex').ActionContext<ProfilerState>} context
     * @param {string} perfume string
     */
    removePerfume(context, perfume) {
      const state = context.state
      const index = state.perfumes.indexOf(perfume.toString())
      if (index === -1)
        return console.warn('[Bestiario warn] perfume', perfume, "doesn't have in user perfumes")
      eventTracker.emitEvent('REMOVE_FROM_PROFILE', { id: perfume.id, type: 'PERFUME', resultingProfile: {
        perfumes: state.perfumes.filter(id => id != perfume.id),
        concepts: state.concepts,
      }})
      const copy = [...state.perfumes]
      copy.splice(index, 1)
      context.commit(SET_PERFUMES, copy)
    },
    /**
     *
     * @param {import('vuex').ActionContext<ProfilerState>} context
     * @param {Object} param1
     * @param {string} param1.user
     * @param {string} param1.password
     */
    login(context, { user, password }) {
      context.commit(SET_FETCHING, true)
      return api
        .login(user, password)
        .then(user => {
          context.commit(SET_USER, user)
          return
        })
        .catch(err => {
          // if user is not null, it mean that user is logged before, so we need to remove state
          if (context.state.user !== null) {
            context.commit(SET_TO_DEFAULT)
          } else {
            context.commit(SET_USER, null)
          }
          return Promise.reject(err)
        })
        .finally(() => {
          context.commit(SET_FETCHING, false)
        })
    },
  },
  getters: {
    hasPerfumeFunction: state => id => {
      return state.perfumes.indexOf(id.toString()) > -1
    },
    perfumesIds: state => state.perfumes.map(p => Number(p)),
    user: state => state.user,
  },
}
