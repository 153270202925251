/**
 *
 * @param {string[]} options
 * @return {{[key:string]:{queryString:string.type:any}}}
 */
function getFieldAndQSKeys(options) {
  return options.reduce((obj, field) => {
    obj[field] = {
      queryString: field,
      type: String,
    }
    return obj
  }, {})
}
function warn(...messages) {
  console.warn('%c[QueryStringPlugin WARN]', 'color:red', ...messages)
}
warn.dev = function(...messages) {
  warn('[dev only]', ...messages)
}
const isDevelopment = process.env.NODE_ENV === 'development'
/**
 *
 * @param {{[field:string]:string}} option
 * @return {{[key:string]:{queryString:string.type:any}}}
 */
function getFieldAndQSKeysFromObj(options) {
  return Object.keys(options).reduce((obj, key) => {
    if (typeof options[key] === 'string')
      obj[key] = {
        queryString: options[key],
        type: String,
      }
    else {
      if (!options[key].queryString)
        console.error(`[QueryStringPlugin Error] key "${key}" must have field "queryString"`)
      obj[key] = {
        queryString: options[key].queryString,
        type: options[key].type ?? String,
      }
    }
    return obj
  }, {})
}
/**
 *
 * @param {*} options
 * @returns {{[key:string]:{queryString:string,type:any}}}
 */
function transformOptions(options) {
  if (Array.isArray(options)) return getFieldAndQSKeys(options)
  return getFieldAndQSKeysFromObj(options)
}
/**
 *
 * @param {Object} query query json
 * @param {{queryString:string,type:any}} fieldObj field config
 */
function getFromQueryString(query, fieldObj) {
  const getter = query[fieldObj.queryString]
  switch (fieldObj.type) {
    case String:
      return getter?.toString()
    case Number:
      return Number(getter)
    case Array:
      if (getter) return Array.isArray(getter) ? getter.map(e => e.toString()) : [getter.toString()]
      return []
    case Boolean:
      if (getter)
        return getter.toString() === 'true' ? true : getter.toString() === 'false' ? false : null
      else return false
    default:
      warn(`type "${fieldObj.type}" of field "${JSON.stringify(fieldObj)}" not controlled`)
      return getter
  }
}
export default {
  /**
   *
   * @param {import('vue').VueConstructor & {$options:{queryString?:{[field:string]:string} | string[]}} Vue
   */
  install(Vue) {
    // Object with key are internal field and values are the values in query string

    Vue.mixin({
      beforeCreate() {
        if (!this.$options.queryString) return
        let fieldObj = transformOptions(this.$options.queryString)
        let internalFields = Object.keys(fieldObj)
        let computed = Object.keys(fieldObj).reduce((result, internalField) => {
          result[internalField] = {
            get() {
              const field = fieldObj[internalField]
              var result = getFromQueryString(this.$route.query, field)
              if (result === undefined || result === null) {
                result = ''
              }
              return result
            },
            set(value) {
              if (value === this[internalField]) {
                if (isDevelopment) warn.dev('push blocked because value is the same')
                return
              }
              return this.$router.push({
                ...this.$route,
                query: {
                  ...this.$route.query,
                  [fieldObj[internalField].queryString]: value,
                },
              })
            },
          }
          return result
        }, {})
        // WARNING for development
        if (isDevelopment)
          Object.keys(this.$options.computed).forEach(key => {
            if (internalFields.includes(key)) warn.dev(`key ${key} is already used`)
          })
        this.$options.computed = {
          ...this.$options.computed,
          ...computed,
        }
      },
      methods: {
        /**
         *
         * @param {import('vue-router').RawLocation} param0
         */
        $pushHistory(obj) {
          var query = obj.query
          console.log(query)
          if (this.$options.queryString) {
            let fieldObj = transformOptions(this.$options.queryString)
            Object.keys(fieldObj).map(key => {
              if (query[key] != null) {
                var value = query[key]
                query[fieldObj[key].queryString] = value
                delete query[key]
              }
            })
          }
          this.$router.push({ ...obj, query })
        },
      },
    })
  },
  getFromQueryString,
  /**
   *
   * @param {Object} query query object
   * @param {T} options options plugin
   * @template T  extends {[key: string]:{queryString:string,type:any} |string} | K[]
   * @returns {{[ K in keyof T]:{queryString:string,getter:String |Array |Number |Boolean}}}
   */
  getQueryStringFromPluginOption(query, options) {
    let fieldObj = transformOptions(options)
    return Object.keys(fieldObj).reduce((obj, key) => {
      obj[key] = {
        ...obj,
        getter: getFromQueryString(query, fieldObj[key]),
      }
      return obj
    }, {})
  },
}
