export default {
welcome_to_bestiario: "This is a new Bestiario_ project",
concept_info: "Welche dieser Begriffe beschreibt den gewünschten Duft am besten?",
next: "Weiter",
back: "Zurück",
back_to_douglas: "zurück zum Shop",
stepper_title_1: "Lieblingsdüfte angeben",
stepper_title_2: "Wunschduft beschreiben",
stepper_title_3: "Geschlecht wählen",
stepper_title_4: "Deine Empfehlung",
select_all: "All",
male: "Herren",
female: "Damen",
unisex: "Unisex",
selector_gender: "Für wen",
selector_brand: "Marke",
selector_families: "Duftnote",
searching: "suchen",
accept: "Einloggen",
cancel: "Abbrechen",
welcome_title: "Herzlich willkommen <br/> beim Parfum-Finder!",
welcome_subtitle: "Erstelle ein individuelles Duftprofil für dich selbst oder eine Person, die du beschenken möchtest, und wir empfehlen dir das passende Parfum.",
welcome_question: "Wonach suchst du?",
welcome_for_me: "DUFT FÜR MICH",
welcome_for_gift: "DUFTGESCHENK",
search_view_title: "Lieblingsdüfte angeben",
search_view_info: "Für eine möglichst treffsichere Empfehlung wähle 2 bis 3 Düfte aus.",
search_fragrance_info: "Falls du zuvor nur einen Duft angegeben hast, brauchen wir hier mindestens drei Angaben von dir",
search_placeholder: "Suche nach Duft- oder Markennamen",
search_view_tipp_for_me:"Tipp: Hier können Düfte oder Marken dabei sein, die du du bereits besitzt, ausprobiert hast oder von Freund*innen kennst.",
search_view_tipp_for_gift:"Tipp: Welche Düfte mag oder besitzt die Person, die du beschenken möchtest? Gib hier Parfums an, von denen du weißt, dass sie sie mag.",
search_fragrance_quantity: "Ausgewählte Düfte",
search_fragrance_remove: "Liste löschen",
search_not_found: "Bitte überprüfe, ob sich vielleicht ein Tippfehler eingeschlichen hat, oder probiere es mit einem anderen Suchbegriff. Beachte auch, dass einige Marken zurzeit noch nicht im Parfum-Finder integriert sind.",
concept_leicht: "Leicht",
concept_intensiv: "Intensiv",
concept_alltag: "Alltag",
concept_anlass: "Anlass",
concept_klassiker: "Klassiker",
concept_neuheit: "Neuheit",
concept_beruhigend: "Beruhigend",
concept_betörend: "Betörend",
recommendation_general_title_singular: "Dieses Profil enthält Düfte aus der Duftfamilie %FAMILIES%!",
recommendation_general_sub_title_singular: "Außerdem passt zu diesem Profil die Duftnuance von folgender Unterfamilie: %FAMILIES%",
recommendation_general_title_plural: "Dieses Profil enthält Düfte aus den Duftfamilien %FAMILIES%!",
recommendation_general_sub_title_plural: "Außerdem passen zu diesem Profil die Duftnuancen aus folgenden Unterfamilien: %FAMILIES%",
recommendation_and: "und",
recommendation_top_6_title: "Top 6: Unsere Duft-Tipps passend zu deiner Auswahl",
recommendation_top_6_sub_title: "Diese Empfehlungen haben wir basierend auf deinen Angaben zusammengestellt.",
recommendation_edit_preferences: "Angaben bearbeiten",
recommendation_your_favorite_ings: "Lieblings-Inhaltsstoffe",
recommendation_more_reco_all_reco: "Alle Empfehlungen",
recommendation_more_reco_title: "Weitere Empfehlungen basierend auf diesem Duftprofil",
recommendation_more_reco_sub_title:"",
not_loged_modal_title: 'Angaben bearbeiten?',
not_loged_modal_description: 'Wenn du jetzt zum Anfang zurückkehrst, gehen deine Angaben und Empfehlungen verloren. Logg dich ein, damit deine Angaben gespeichert werden!',
not_loged_modal_title_wishlist: 'Du bist nicht eingeloggt!',
not_loged_modal_description_wishlist: 'Um diese Produkte in deine Wunschliste aufzunehmen, musst du eingeloggt sein.',
reco_not_loged_modal_footer: "Noch nicht Douglas-Kund*in? Hier anmelden.",
perfume_view_predominant_ingredient: "Haupt-Duftnoten",
breadcumbs_header_your_recommendation: "Deine Empfehlungen",
perfume_view_go_product: "Zum Produkt",
perfume_view_wishlist: "Merkzettel",
perfume_view_remove_wistlist: "Merkzettel entfernen",
perfume_view_families_and_subfamilies: "Olfaktorische Familie",
perfume_view_belong_family: "Dieses Parfum gehört zu der Haupt-Duftfamilie",
perfume_view_belong_subfamily: "und zu der Unterfamilie",
perfume_view_other_ingredients: "Alle Duftnoten",
perfume_view_why: "Warum empfehlen wir dir diesen Duft?",
perfume_view_why_text: "Diese Empfehlung kommt dem Duftprofil sehr nahe und enthält einige der favorisierten Duftnoten: %ING%",
perfume_view_not_loged_modal_title: "Du bist nicht eingeloggt!",
perfume_view_not_loged_modal_description: "Um diese Produkte auf deinen Merkzettel aufzunehmen, musst du eingeloggt sein. ",
perfume_view_not_loged_modal_footer: "Noch nicht Douglas-Kund*in? Hier anmelden.",
introduce_password: "Passwort eingeben",
password: "Passwort",
username: "E-Mail-Adresse'",
login_title: "Ich bin bereits Douglas-Kund*in",
login_sub_title: "",
login_required: "Pflichtfeld",
login_log_in_button: "Anmelden",
login_password_forgot: "Passwort vergessen?",
login_stay_logged: "Eingeloggt bleiben",
login_new_in_douglas_title: "Ich bin neu bei Douglas",
login_new_in_douglas_button: "Konto erstellen",
perfume_not_found:'Es tut uns leid, aber dieses Parfüm ist leider nicht mehr verfügbar.',
data_protection:"Datenschutz",
help_imprint:"Impressum",
cookies_polices:"Datenschutzeinstellungen",
cookie_modal_title:"Cookie Informationen",
cookie_modal_content:"Wir verwenden Cookies und andere Technologien, um die Funktionen unserer Websites bereitzustellen, soziale Medien einzubinden, Inhalte und Anzeigen zu personalisieren und um Zugriffe auf unsere Websites zu analysieren. Wir empfehlen Ihnen, uns die Nutzung von Cookies und die Auswertung Ihrer Website-Nutzung für diese Zwecke zu erlauben. In diesem Fall geben wir bestimmte Informationen zu Ihrer Verwendung unserer Website an unsere jeweiligen Partner für soziale Medien (z.B. Facebook), Werbung (z.B. Google) und Analysen weiter, auch an Partner in den USA. Die Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung anderer Dienste gesammelt haben. Es besteht das Risiko, dass Ihre Daten bei diesen US-Unternehmen nicht in der gleichen Weise geschützt sind, wie es in der Europäischen Union der Fall ist. Klicken Sie auf „Einwilligen“, um Ihre Zustimmung zu erteilen. Sie können diese Einwilligung jederzeit widerrufen. Klicken Sie auf „Mehr Informationen“, um weitere Informationen zu erhalten und um Einstellungen zu ändern.",
cookie_modal_more_info:"Mehr Informationen",
maintenance_message:"Oops… Douglas Parfum-Finder befindet sich derzeit in wartung. Wir kommen sehr bald wieder! Besuchen sie in der zwischenzeit Douglas.de"
}