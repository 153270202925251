import { render, staticRenderFns } from "./Fragrance.vue?vue&type=template&id=6936c98c&scoped=true&"
import script from "./Fragrance.vue?vue&type=script&lang=js&"
export * from "./Fragrance.vue?vue&type=script&lang=js&"
import style0 from "./Fragrance.vue?vue&type=style&index=0&id=6936c98c&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6936c98c",
  null
  
)

export default component.exports