<template>
  <button
    class="back-button back-btn btn btn--secondary btn--ghost btn--small has-icon"
    :class="{disabled:disabledBackBtn,hide:!visibleNextBtn}"
    @click="handleBack"
  >
    <div class="icon back" /> 
    {{ $t("back") }}
  </button>
</template>

<script>
import StepHeaderMixin from '../../mixins/StepHeaderMixin'
import { ROUTER_NAMES } from '../../settings'
export default {
  mixins: [StepHeaderMixin],
  computed: {
    disabledBackBtn() {
      return this.selected < 0
    },

    visibleNextBtn() {
      return this.$route.name !== ROUTER_NAMES.RECOMMENDATIONS
    },
  },
  methods: {
    handleBack() {
      // find the last routeStep in history and replace it
      const indexStep = this.selected
      if (indexStep <= 0) return this.$routerPush({ name: ROUTER_NAMES.WELCOME })
      else {
        const lastRouteName = this.steps[indexStep - 1].routeName
        const routes = this.$route.matched
        const index = routes.findIndex(route => lastRouteName === route.name)
        if (index < 0) {
          this.$routerPush({
            name: lastRouteName,
          })
        } else this.$router.go(index - routes.length)
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.back-button
  align-content: center
  font-size: 14px !important
  line-height: 22 !important
  transition: opacity 0.3s !important

  .mobile &
    font-size: 16px !important
    line-height: 26 !important

  &.hide
    display: none
    opacity: 0
    cursor: default
    cursor: not-allowed
    pointer-events: none

  &.has-icon
    .mobile &
      padding: 0 !important

    &.back-btn
      padding-right: 1.75em
      padding-left: 0.5em

    &.next-btn
      padding-right: 0.5em
      padding-left: 1.75em
      color: #fff

      .icon
        &.next
          m-icon("arrow-right-white", 24)

        .mobile &
          display: none

  .icon
    &.back
      m-icon("arrow-right", 24)
      margin-right: 0.5em
      transform: rotate(180deg)

    &.next
      m-icon("arrow-right", 24)
      margin-left: 0.5em

    .mobile &
      display: none
      marign: 0
</style>